/** @jsxImportSource @emotion/react */
import { Flex } from "antd";
import { useRecoilValue } from "recoil";
import { onboardingState, userRoleState } from "../../states/auth";
import { OnboardingStep, UserRoleType } from "../../types/user";
import TeacherHomeComponent from "./teacher";
import SuperAdminHomeComponent from "../super-admin/home/super-admin";
import SpinnerComponent from "../common/spinner";
import SchoolAdminHomeComponent from "./school-admin";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ParentHomeComponent from "./parent";

const HomeComponent = () => {
  const userRole = useRecoilValue(userRoleState);
  const onboarding = useRecoilValue(onboardingState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!onboarding?.complete && onboarding?.step) {
      navigate(`/onboarding/${OnboardingStep[onboarding?.step]}`);
    }
  }, [navigate, onboarding]);

  if (userRole?.type === UserRoleType.SUPER_ADMIN) {
    return <SuperAdminHomeComponent />;
  }

  if (userRole?.type === UserRoleType.SCHOOL_ADMIN) {
    return <SchoolAdminHomeComponent />;
  }

  if (userRole?.type === UserRoleType.TEACHER) {
    return <TeacherHomeComponent />;
  }

  if (userRole?.type === UserRoleType.PARENT) {
    return <ParentHomeComponent />;
  }

  return (
    <Flex style={{ height: "100%" }} justify="center" align="center">
      <SpinnerComponent size="large" />
    </Flex>
  );
};

export default HomeComponent;
