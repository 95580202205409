/** @jsxImportSource @emotion/react */

import { Button, Dropdown, Typography } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currentUserNameWithGreetingState } from "../../states/auth";
import styled from "styled-components";
import { schoolState } from "../../states/school";
import { classroomsState } from "../../states/classrooms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faGear } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { studentsState } from "../../states/student";
import { css } from "@emotion/react";
import { useAuth } from "../../context/auth-context";

const Mascot = styled.img`
  height: 35px;
  width: auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const NavText = styled.span`
  cursor: pointer;
`;

const BaseNavComponent = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Container>
      <LogoContainer>
        <Mascot
          src={`${process.env.PUBLIC_URL}/icon.png`}
          alt="Clear Day Mascot"
        />
        <Typography.Title level={4}>Clear Day</Typography.Title>
      </LogoContainer>
      {children}
    </Container>
  );
};

const TopNavComponent = () => {
  const school = useRecoilValue(schoolState);
  const classrooms = useRecoilValue(classroomsState);
  const allStudents = useRecoilValue(studentsState);
  const navigate = useNavigate();
  const location = useLocation();
  const greeting = useRecoilValue(currentUserNameWithGreetingState);
  const { classroomId, studentId } = useParams();
  const { handleUserLogout } = useAuth();

  const selectedClassroom =
    classroomId !== undefined
      ? classrooms.find((classroom) => classroom.id === Number(classroomId))
      : undefined;

  const selectedStudent =
    studentId !== undefined
      ? allStudents.find((student) => student.id === Number(studentId))
      : undefined;

  const HomeLink = (
    <NavText>
      <Typography.Title level={5} onClick={() => navigate("/")}>
        {school?.name}
      </Typography.Title>
    </NavText>
  );

  const classroomLink = (
    <NavText>
      <Typography.Title
        level={5}
        onClick={() => navigate(`/classroom/${classroomId}`)}
      >
        {selectedClassroom?.name}
      </Typography.Title>
    </NavText>
  );

  const studentLink = (
    <NavText>
      <Typography.Title
        level={5}
        onClick={() => navigate(`/student/${selectedStudent?.id}`)}
      >
        {selectedStudent?.firstName} {selectedStudent?.lastName}
      </Typography.Title>
    </NavText>
  );

  const settingsButton = (
    <div
      css={css`
        margin-left: auto;
        margin-right: 135px;

        @media (max-width: 768px) {
          margin-right: 0;
        }
      `}
    >
      <Dropdown
        menu={{
          items: [
            {
              label: <div onClick={() => handleUserLogout()}>Log Out</div>,
              key: "logout",
            },
          ],
        }}
      >
        <Button icon={<FontAwesomeIcon icon={faGear} />} />
      </Dropdown>
    </div>
  );

  if (location.pathname === "/") {
    return (
      <BaseNavComponent>
        <Typography.Title level={5}>{greeting}</Typography.Title>
        {settingsButton}
      </BaseNavComponent>
    );
  }

  if (selectedClassroom !== undefined && selectedStudent === undefined) {
    return (
      <BaseNavComponent>
        {HomeLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {classroomLink}
      </BaseNavComponent>
    );
  }

  if (selectedClassroom !== undefined && selectedStudent !== undefined) {
    return (
      <BaseNavComponent>
        {HomeLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {classroomLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {studentLink}
      </BaseNavComponent>
    );
  }

  if (selectedStudent !== undefined) {
    return (
      <BaseNavComponent>
        {HomeLink}
        <FontAwesomeIcon icon={faCaretRight} />
        {studentLink}
      </BaseNavComponent>
    );
  }

  return <BaseNavComponent>{HomeLink}</BaseNavComponent>;
};
export default TopNavComponent;
