/** @jsxImportSource @emotion/react */
import {
  Alert,
  Button,
  Card,
  Flex,
  Input,
  Select,
  Slider,
  Table,
  Typography,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Student } from "../../types/student";
import { useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../context/auth-context";
import {
  EventCreateManyRequest,
  EventCreateOneRequest,
  EventType,
} from "../../types/event";
import SpinnerComponent from "../common/spinner";
import { TableContainer } from "../common/styled";
import { AmountCompletedText } from "../student/log-event-form";
import DividerComponent from "../common/divider";
import { SuccessResponse } from "../../types/common";

const { Paragraph } = Typography;

const Container = styled.div`
  margin: 24px 32px 0px;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 48px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 12px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 40%;

  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

const AiEventWizard = ({
  selectedStudentsToLog,
  onOk,
  onCancel,
}: {
  selectedStudentsToLog: Student[];
  onOk(): void;
  onCancel(): void;
}) => {
  const { fetchWithToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [eventText, setEventText] = useState("");
  const [aiResults, setAiResults] = useState<EventCreateOneRequest[]>([]);

  const [error, setError] = useState(false);

  const handleFetchDraftEvents = async () => {
    try {
      setLoading(true);

      const response = await fetchWithToken({
        url: `/autocomplete/student-event`,
        method: "POST",
        body: { requestText: eventText },
        stream: true,
      });

      if (response.body) {
        const reader = response.body.getReader();
        let chunks = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            try {
              const results = JSON.parse(chunks) as EventCreateOneRequest[];

              setAiResults(results);

              setError(false);

              if (results.length === 0) {
                setError(true);
              }
            } catch (error) {
              setError(true);
            }
            break;
          }
          chunks += new TextDecoder("utf-8").decode(value);
        }
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const handleSaveEvents = async () => {
    try {
      setLoading(true);

      const eventsToCreate: EventCreateManyRequest = {
        events: aiResults.map((result) => ({
          ...result,
          studentId: selectedStudentsToLog[0].id,
        })),
      };

      const saveEventsResponse = (await fetchWithToken({
        url: `/student/events`,
        method: "POST",
        body: eventsToCreate,
      })) as SuccessResponse | undefined;

      onOk();
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  /*

  Backend LLM streaming request

  const createEvent = async () => {
    try {
      console.log("called");
      const response = await fetch(`${API_URL}/autocomplete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requestText: "bathroom" }),
      });

      console.log("2");

      if (response.body) {
        console.log("yes");
        const reader = response.body.getReader();
        let chunks = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            console.log(chunks);
            setText(chunks);
            break;
          }
          chunks += new TextDecoder("utf-8").decode(value);
          setText(chunks);
          console.log(chunks);
        }
      }

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };*/

  const handleEditEvent = (
    index: number,
    keyToUpdate: keyof EventCreateOneRequest,
    updatedValue: string | number
  ) => {
    const updatedAiResults = [...aiResults];
    updatedAiResults[index] = {
      ...updatedAiResults[index],
      [keyToUpdate]: updatedValue,
    };
    setAiResults(updatedAiResults);
  };

  return (
    <Container>
      <SpinnerComponent spinning={loading}>
        <Flex vertical gap={12} justify="center">
          <div>
            <Button
              onClick={() => onCancel()}
              type="link"
              icon={<ArrowLeftOutlined />}
              style={{ paddingLeft: 0 }}
            >
              Back to Classroom
            </Button>
          </div>
          <ContentContainer>
            <FormContainer>
              <Typography.Title level={4}>Magic Assist</Typography.Title>
              {error && (
                <Alert
                  message="We couldn't figure out how to handle your request. Please review your request and ensure it is on topic to the child."
                  type="error"
                  showIcon
                />
              )}
              <Typography.Text>
                Describe the child's day and we'll automatically generate
                highlights for you. You'll be able to edit and confirm each
                highlight before saving.
              </Typography.Text>
              <Input.TextArea
                cols={6}
                value={eventText}
                onChange={(event) => setEventText(event.currentTarget.value)}
                status={error ? "error" : undefined}
              />
              <Button onClick={() => handleFetchDraftEvents()}>
                {aiResults.length === 0
                  ? "Generate Highlights"
                  : "Re-generate Highlights"}
              </Button>
            </FormContainer>
            {aiResults.length > 0 && (
              <ResultsContainer>
                <Typography.Title level={5}>
                  The moments we generated for you are ready for review:
                </Typography.Title>
                <Card style={{ margin: "12px 0" }}>
                  <Flex gap={20} vertical>
                    {aiResults.map((result, index) => (
                      <div>
                        <Flex vertical gap={16}>
                          <Flex gap={16} align="center">
                            <Select
                              style={{ width: 150 }}
                              options={[
                                {
                                  value: EventType.ACTIVITY,
                                  label: "Activity",
                                },
                                {
                                  value: EventType.FOOD,
                                  label: "Food",
                                },
                                {
                                  value: EventType.BATHROOM,
                                  label: "Bathroom",
                                },
                                {
                                  value: EventType.NAP,
                                  label: "Nap",
                                },
                                {
                                  value: EventType.NOTE,
                                  label: "Note to parent",
                                },
                              ]}
                              value={result.type}
                              onChange={(value) =>
                                handleEditEvent(index, "type", value)
                              }
                            />
                            <Input.TextArea
                              onChange={(event) =>
                                handleEditEvent(
                                  index,
                                  "message",
                                  event.currentTarget.value
                                )
                              }
                              value={result.message}
                            />
                          </Flex>
                          {result.type === EventType.FOOD && (
                            <Slider
                              min={0}
                              max={4}
                              defaultValue={0}
                              marks={AmountCompletedText}
                              value={
                                result.amountCompleted !== null
                                  ? result.amountCompleted
                                  : undefined
                              }
                              onChange={(value) =>
                                handleEditEvent(index, "amountCompleted", value)
                              }
                            />
                          )}
                        </Flex>
                        {index + 1 !== aiResults.length && <DividerComponent />}
                      </div>
                    ))}
                  </Flex>
                </Card>
                <Button onClick={() => handleSaveEvents()}>
                  Save Highlights
                </Button>
              </ResultsContainer>
            )}
          </ContentContainer>
        </Flex>
      </SpinnerComponent>
    </Container>
  );
};

export default AiEventWizard;
