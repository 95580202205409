import { Tag } from "antd";
import { Student, StudentStatusType } from "../types/student";
import Cookies from "js-cookie";

export const API_URL = process.env.REACT_APP_API_URL;
export const ACCESS_TOKEN_COOKIE_KEY = "access_token";

export const getGreeting = () => {
  const currentHour = new Date().getHours();
  if (currentHour < 12) {
    return "Good Morning";
  } else if (currentHour < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

export const getStudentTag = (student: Student) => {
  switch (student.status) {
    case StudentStatusType.CHECKED_IN:
      return <Tag color="green">Checked In</Tag>;
    case StudentStatusType.NOT_PRESENT:
      return <Tag color="cyan">Checked Out</Tag>;
    case StudentStatusType.ABSENT:
      return <Tag color="purple">Absent</Tag>;
  }
};

export const getHumanReadableEnum = (enumValue: string): string => {
  const replaced = enumValue.replace(/_/g, " ");
  return replaced.charAt(0).toUpperCase() + replaced.slice(1).toLowerCase();
};

export const setAuthCookie = (accessToken: string) => {
  Cookies.set(ACCESS_TOKEN_COOKIE_KEY, accessToken, {
    expires: 5,
    secure: true,
    sameSite: "strict",
  });
};

export const getAuthCookie = () => {
  return Cookies.get(ACCESS_TOKEN_COOKIE_KEY);
};

export const removeAuthCookie = () => {
  Cookies.remove(ACCESS_TOKEN_COOKIE_KEY);
};

export interface FetchWithTokenProps {
  url: string;
  method: "GET" | "POST";
  body?: object;
  stream?: boolean;
}

/**
 *
 * Do not use directly in components. Only auth-context or recoil should access this function
 */
export const utilFetchWithToken = async ({
  url,
  method,
  body,
  accessToken,
  stream,
}: FetchWithTokenProps & {
  accessToken: string;
}) => {
  const response = await fetch(`${API_URL + url}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  if (stream) {
    return response;
  }

  return await response.json();
};
