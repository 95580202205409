import { atom, selector } from "recoil";
import {
  GetCurrentUserResponse,
  UserOnboarding,
  UserRole,
} from "../types/user";
import { getGreeting } from "../util";

export const accessTokenState = atom<string | undefined>({
  key: "accessTokenState",
  default: undefined,
});

export const currentUserState = atom<GetCurrentUserResponse | undefined>({
  key: "currentUserState",
  default: undefined,
});

export const onboardingState = selector<UserOnboarding | undefined>({
  key: "onboardingState",
  get: ({ get }) => {
    const currentUser = get(currentUserState);

    if (!currentUser?.onboarding || currentUser.onboarding.length === 0) {
      return undefined;
    }

    return currentUser.onboarding[0];
  },
});

export const userRoleState = selector<UserRole | undefined>({
  key: "userRoleState",
  get: ({ get }) => {
    const currentUser = get(currentUserState);
    if (!currentUser?.roles || currentUser.roles.length === 0) {
      return undefined;
    }

    const roleHierarchy = ["PARENT", "TEACHER", "SCHOOL_ADMIN", "SUPER_ADMIN"];
    const highestRole = currentUser.roles.reduce((highest, role) => {
      return roleHierarchy.indexOf(role.type) >
        roleHierarchy.indexOf(highest.type)
        ? role
        : highest;
    });

    return highestRole;
  },
});

export const currentUserNameWithGreetingState = selector<string | undefined>({
  key: "currentUserNameWithGreetingState",
  get: ({ get }) => {
    const currentUser = get(currentUserState);

    if (!currentUser) {
      return undefined;
    }

    return `${getGreeting()}, ${currentUser.firstName} ${currentUser.lastName}`;
  },
});
