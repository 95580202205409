/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import { Flex, Menu, MenuProps } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faChalkboard,
} from "@fortawesome/free-solid-svg-icons";
import { ItemType, MenuItemType } from "antd/es/menu/hooks/useItems";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import SpinnerComponent from "../common/spinner";
import { userRoleState } from "../../states/auth";
import { useRecoilValue } from "recoil";
import { UserRoleType } from "../../types/user";
import TopNavComponent from "./top-nav";

/*

media-query breakpoints

xs: for screens less than 576px wide
sm: for screens 576px wide and up
md: for screens 768px wide and up
lg: for screens 992px wide and up
xl: for screens 1200px wide and up
xxl: for screens 1400px wide and up 

*/

const StyledParentContainer = styled(Flex)`
  .ant-typography {
    h1,
    h2 {
      font-weight: 400;
    }
  }
`;

const Header = styled(Flex)`
  border-bottom: 2px solid #ccccff;
  padding: 10px 10px;

  h3,
  h4,
  h5 {
    margin-top: 0 !important;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const ContentContainer = styled.div`
  flex: auto;
  border-radius: 12px;
  margin: 0 100px;
  padding: 20px;

  @media (max-width: 992px) {
    margin: 0;
  }

  @media (max-width: 767px) {
    margin: 0px;
    padding: 0px;
    margin-left: 0;
    margin-bottom: 120px;
  }
`;

const BottomNavbar = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fffaee;

  border-top: 2px solid #ccccff;

  .ant-menu {
    background-color: #fffaee;
  }

  @media (min-width: 767px) {
    display: none;
  }
`;

export enum RouteNames {
  SCHOOLS = "Schools",
  CLASSROOMS = "Classrooms",
  MESSAGES = "Messages",
  SETTINGS = "Settings",
}

export const Routes: Record<string, string> = {
  Classrooms: "/",
  Schools: "/schools",
  Messages: "/messages",
  Settings: "/settings",
};

const baseMenuItems: ItemType<MenuItemType>[] = [
  /*{
    label: RouteNames.SETTINGS,
    key: RouteNames.SETTINGS,
    icon: <FontAwesomeIcon icon={faGear} />,
  },*/
  {
    label: "Log Out",
    key: "LOGOUT",
    icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
  },
];

const teacherMenuItems: ItemType<MenuItemType>[] = [
  {
    label: RouteNames.CLASSROOMS,
    key: RouteNames.CLASSROOMS,
    icon: <FontAwesomeIcon icon={faChalkboard} />,
  },
  /*{
    label: RouteNames.MESSAGES,
    key: RouteNames.MESSAGES,
    icon: <FontAwesomeIcon icon={faComments} />,
  },*/
  ...baseMenuItems,
];

const schoolAdminMenuItems: ItemType<MenuItemType>[] = [...teacherMenuItems];

const superAdminMenuItems: ItemType<MenuItemType>[] = [
  {
    label: RouteNames.SCHOOLS,
    key: RouteNames.SCHOOLS,
    icon: <FontAwesomeIcon icon={faChalkboard} />,
  },
  ...baseMenuItems,
];

const AuthenticatedIndexComponent: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, handleUserLogout } = useAuth();
  const userRole = useRecoilValue(userRoleState);
  const [loading, setLoading] = useState(true);

  const handleOnMenuClick: MenuProps["onClick"] = (e) => {
    const key = e.key;

    if (key) {
      if (key === "LOGOUT") {
        handleUserLogout();
      } else {
        navigate(Routes[key]);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      setLoading(false);
    }
  }, [currentUser]);

  const isOnboardingRoute = location.pathname.startsWith("/onboarding");

  if (loading) {
    return (
      <Flex justify="center" align="center" style={{ height: "100vh" }}>
        <SpinnerComponent />
      </Flex>
    );
  }

  if (isOnboardingRoute) {
    return (
      <Flex justify="center" style={{ height: "100vh", marginTop: 80 }}>
        <Container fluid>{children}</Container>
      </Flex>
    );
  }

  let menuToShow = baseMenuItems;

  if (userRole?.type === UserRoleType.SUPER_ADMIN) {
    menuToShow = superAdminMenuItems;
  }

  if (userRole?.type === UserRoleType.SCHOOL_ADMIN) {
    menuToShow = schoolAdminMenuItems;
  }

  if (userRole?.type === UserRoleType.TEACHER) {
    menuToShow = teacherMenuItems;
  }

  return (
    <StyledParentContainer
      vertical
      style={{ height: "100vh", padding: 0, margin: 0 }}
    >
      {/*}
      <StyledSidebar>
        <Typography.Title>Clear Day</Typography.Title>
        <Menu mode="inline" items={menuToShow} onClick={handleOnMenuClick} />
      </StyledSidebar>
  */}
      <Header gap={18} align="center">
        <TopNavComponent />
      </Header>
      <ContentContainer>{children}</ContentContainer>
      <BottomNavbar>
        <Menu
          disabledOverflow={true}
          mode="horizontal"
          items={menuToShow}
          onClick={handleOnMenuClick}
        />
      </BottomNavbar>
    </StyledParentContainer>
  );
};

export default AuthenticatedIndexComponent;
